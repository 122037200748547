import { useState, useEffect, useLayoutEffect } from "react";
import { useIframeMessage } from "@hooks/useIframeMessage";
import { EAppStateType, useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import {
  ISdkIframePostMessage,
  EMode,
  ISdkIframeSettings,
  ISdkUpdateAccessToken,
} from "sdk";
import { NOT_IN_IFRAME } from "@constants/wording";
import {
  createCssFromLink,
  generateToken,
  grantUser,
  initConnection,
  setRocketToken,
  setChannelIdQuery,
  subscribeRoom,
  updateSecureData,
} from "@utils";
import { Livechat } from "@api";
import { handleAppStateCatchError } from "@utils/handleError";
export const useApp = () => {
  const [loading, setLoading] = useState(true);
  const {
    token,
    settings,
    appState,
    setAppState,
    setSettings,
    setUser,
    setRoom,
    setToken,
    clearStorage,
  } = useAppStore(
    (state) => ({
      settings: state.settings,
      appState: state.appState,
      token: state.token,
      user: state.user,
      room: state.room,
      setAppState: state.setAppState,
      setSettings: state.setSettings,
      setUser: state.setUser,
      setRoom: state.setRoom,
      setToken: state.setToken,
      clearStorage: state.clearStorage,
    }),
    shallow
  );

  useIframeMessage({
    listener: (data) => initApp(data),
  });

  // Re accesstoken
  const updateAccessToken = async (payload: ISdkIframePostMessage) => {
    const { topic, data } = payload as {
      topic: string;
      data: ISdkUpdateAccessToken;
    };
    if (topic !== "update-access-token") return;
    if (topic === "update-access-token") {
      await updateSecureData(data.accessToken);
      await reInit();
    }
  };

  const reInit = async () => {
    try {
      setLoading(true);
      setAppState(EAppStateType.RE_INIT, "");
      await initConnection();
      const user = await grantUser(token, {
        channelId: settings.channelId,
        displayName: settings.displayName,
        imageUrl: settings.imageUrl,
      });
      const room = await subscribeRoom();
      setUser(user);
      setRoom(room);
      setLoading(false);
      setAppState(EAppStateType.READY, "");
    } catch (error) {
      handleAppStateCatchError(error, setAppState);
      setLoading(false);
    }
  };

  // Re accesstoken
  useIframeMessage({
    listener: updateAccessToken,
    deps: [token, settings],
  });

  useEffect(() => {
    // intialize app for standalone mode in case you run only client
    const inIframe = isInIframe();
    if (!inIframe && import.meta.env.MODE !== "standalone") {
      setLoading(false);
      setAppState(EAppStateType.NOT_IN_IFRAME, NOT_IN_IFRAME);
      return;
    }
    if (import.meta.env.MODE === "standalone") {
      // if you want to add setting you can add here
      // this will use full screen mode by default if you run yarn dev:client:sta
      initApp({
        topic: "init",
        data: {
          channelId: import.meta.env.VITE_CHANNEL_ID,
          mode: EMode.FULL_SCREEN,
          richmenuSetting: {
            fixedHeight: 200, //px if add this divided by will not effect
            dividedBy: 3, // formular of this is window.innerWidth (100% width of iframe) / 1.666666666666666666666666666 / dividedBy
          },
          linkTarget: "_self",
        },
        sender: "ams_sdk",
      });
    }

    return () => {
      Livechat.disconnect();
    };
  }, []);

  const isInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const initApp = async (data: ISdkIframePostMessage) => {
    try {
      setLoading(true);
      setAppState(EAppStateType.INITIALIZING, "");
      const { topic, data: settings } = data as {
        topic: string;
        data: ISdkIframeSettings;
        sender: "ams_sdk";
      };
      createCssFromLink(settings.cssLinks);
      if (topic === "init") {
        await initialize(settings);
        setAppState(EAppStateType.READY, "");
      }
      if (topic === "re-init") {
        clearStorage();
        await initialize(settings);
        setAppState(EAppStateType.READY, "");
      }
    } catch (err) {
      console.log(err);
      handleAppStateCatchError(err, setAppState);
    } finally {
      setLoading(false);
    }
  };

  const initialize = async (settings: ISdkIframeSettings) => {
    try {
      setSettings({
        ...settings,
      });
      // required channelId
      await setChannelIdQuery(settings.channelId);
      // set api token if exist in settings
      if (settings.secure?.enabled && settings.secure.accessToken) {
        await updateSecureData(settings.secure.accessToken);
      }
      // token in local storage
      const newToken = generateToken(token, settings.token);
      if (!newToken) throw new Error("Token is not set");
      setToken(newToken);

      // set token
      const isSetToken = setRocketToken(newToken);
      if (!isSetToken) {
        throw new Error("User is not set");
      }
      await initConnection();
      const user = await grantUser(newToken, {
        channelId: settings.channelId,
        displayName: settings.displayName,
        imageUrl: settings.imageUrl,
      });
      const room = await subscribeRoom();
      setUser(user);
      setRoom(room);
    } catch (err) {
      throw err;
    }
  };

  return {
    appState,
    token,
    loading,
  };
};
