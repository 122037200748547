import React, { useMemo } from "react";
import { Chat } from "@containers/chat";
import { useApp } from "@useApp";
import { Loading } from "@shares/components";
import { LOADING_TEXT } from "@constants/wording";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import "flex-render/css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@splidejs/react-splide/css";
import { EAppStateType } from "@store/appStore";
// Create a client
const queryClient = new QueryClient();
function App() {
  const { loading, appState } = useApp();
  const chat = useMemo(() => {
    if (
      appState.state !== EAppStateType.READY &&
      appState.state !== EAppStateType.UNAUTHORIZED
    ) {
      return null;
    }
    return <Chat />;
  }, [appState]);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Loading
          active={loading}
          spinner
          text={LOADING_TEXT}
          className="h-[100dvh]"
        >
          {appState.state !== EAppStateType.UNAUTHORIZED && (
            <h1 className="absolute left-[50%] top-[50%] translate-x-[-50%] whitespace-nowrap text-3xl">
              {appState.reason}
            </h1>
          )}
          {chat}
        </Loading>
      </QueryClientProvider>
      {/* disabled type check here */}
      {/* @ts-ignore:next-line */}
      <div className="hidden">version{APP_VERSION}</div>
    </>
  );
}

export default App;
