import { DDPDriver } from "@ams/rocket-sdk/src/lib/drivers/ddp";
import { client, Livechat } from "@api";

export const setChannelIdQuery = async (channelId: string) => {
  const socket = (await Livechat.socket) as DDPDriver;
  socket.ddp.channelId = channelId;
  client.defaults.params = {
    channelId,
  };
  Livechat.client.params = {
    channelId,
  };
};

export const setAuthHeader = (accessToken: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  Livechat.client.headers = {
    ...Livechat.client.headers,
    Authorization: `Bearer ${accessToken}`,
  };
  return true;
};

export const setSecureSocketHost = async (accessToken: string) => {
  const socket = (await Livechat.socket) as DDPDriver;
  socket.ddp.accessToken = accessToken;
};

export const updateSecureData = async (accessToken: string) => {
  setAuthHeader(accessToken);
  await setSecureSocketHost(accessToken);
};
