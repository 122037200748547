import React, { useEffect, useMemo } from "react";
import { useComposer } from "./useComposer";
import { EComposerMode } from "@constants/composer";
import { TextComposer } from "./TextComposer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faKeyboard } from "@fortawesome/pro-regular-svg-icons";
import { RichmenuComposer } from "./RichmenuComposer";
import { EAppStateType, useAppStore } from "@store/appStore";
import shallow from "zustand/shallow";
export type IComposerProps = {
  richmenuState: {
    isLoading: boolean;
    isError: boolean;
  };
};

export const Composer = React.memo(({ richmenuState }: IComposerProps) => {
  const { state, reason, settings } = useAppStore((s) => {
    return {
      state: s.appState.state,
      reason: s.appState.reason,
      settings: s.settings,
    };
  }, shallow);

  const isUnauthorized = useMemo(
    () => state === EAppStateType.UNAUTHORIZED,
    [state, reason]
  );

  const {
    acceptFileTypes,
    richmenu,
    mode,
    textAreaRef,
    fileInputRef,
    message,
    onType,
    onSend,
    onInputFileChange,
    onChangeMode,
  } = useComposer();

  useEffect(() => {
    if (richmenuState.isError && mode === EComposerMode.RICH_MENU) {
      onChangeMode();
    }
  }, [richmenuState.isError]);

  const _renderToggleMenu = () => {
    if (richmenuState.isLoading || richmenuState.isError) {
      return null;
    }
    return (
      <>
        {mode === EComposerMode.CHAT ? (
          <button
            onClick={onChangeMode}
            disabled={isUnauthorized}
            className="flex disabled:cursor-not-allowed disabled:opacity-50"
          >
            <FontAwesomeIcon
              icon={faBars}
              className="mr-[16px] cursor-pointer text-[20px]"
            />
          </button>
        ) : (
          <button
            onClick={onChangeMode}
            disabled={isUnauthorized}
            className="flex disabled:cursor-not-allowed disabled:opacity-50"
          >
            <FontAwesomeIcon
              icon={faKeyboard}
              className="cursor-pointer text-[20px]"
            />
          </button>
        )}
      </>
    );
  };

  const placeholder = useMemo(() => {
    if (isUnauthorized && settings.placeholder?.unAuthorized) {
      return settings.placeholder.unAuthorized;
    }

    return settings.placeholder?.ready || undefined;
  }, [isUnauthorized, settings]);

  return (
    <div className="relative">
      <footer className="relative flex items-center bg-white py-[6px] pl-[20px]">
        {_renderToggleMenu()}
        {mode === EComposerMode.CHAT ? (
          <TextComposer
            acceptFileTypes={acceptFileTypes ?? []}
            textAreaRef={textAreaRef}
            fileInputRef={fileInputRef}
            message={message}
            placeholder={placeholder}
            isDisabled={isUnauthorized}
            onType={onType}
            onSend={onSend}
            onInputFileChange={onInputFileChange}
            attachment={settings.attachment}
          />
        ) : (
          <RichmenuComposer
            isDisabled={isUnauthorized}
            name={richmenu?.chatBarText ?? ""}
          />
        )}
      </footer>
    </div>
  );
});
