import { UNAUTHORIZED_IFRAME_POSTBACK } from "@constants/iframe";
import { FAILED_INIT, UNAUTHORIZED } from "@constants/wording";
import { EAppStateType } from "@store/appStore";

export const handleAppStateCatchError = (
  err: unknown,
  setAppState: (state: EAppStateType, reason: string) => void
) => {
  console.error("error", err);
  if (err instanceof Event || err === 401) {
    window.parent.postMessage(UNAUTHORIZED_IFRAME_POSTBACK, "*");
    setAppState(EAppStateType.UNAUTHORIZED, UNAUTHORIZED);
  } else {
    setAppState(EAppStateType.FAILED_INIT, FAILED_INIT);
  }
};
