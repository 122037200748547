import { DDPDriver } from "@ams/rocket-sdk/src/lib/drivers/ddp";
import { Livechat } from "@api";
import { subscribeRoom } from "./subscribeRoom";
import { richmenuRefetcher } from "@libs/richmenuRefetcher";
export const initConnection = async () => {
  await Livechat.connect({});
  const driver = (await Livechat.socket) as DDPDriver;

  driver.ddp.onEvent("close", async (data) => {
    await Livechat.connect({});
    await subscribeRoom();
    if (richmenuRefetcher.refetch) {
      richmenuRefetcher.refetch();
    }
  });
  return driver;
};
