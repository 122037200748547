import { Livechat } from "@api";
import { MESSAGE_MAX_TAKE } from "@constants";

import {
  genInternalMessage,
  messageTransformer,
  replacePostback,
  transformMessage,
} from "@libs";
import { EAppStateType } from "@store/appStore";
import {
  EMessageStatus,
  EMessageType,
  ESender,
  ICombinedMessage,
  IInCommingMessage,
  IOutGoingPostbackMessage,
  IOutGoingResponse,
  IRoom,
  IUser,
} from "@types";
import { IOutGoingMessage } from "@types";
import { handleAppStateCatchError } from "@utils/handleError";
import dayjs from "dayjs";
import { assign, createMachine } from "xstate";

export type IChatMachineBoxContext = {
  firstItemIndex: number;
  messages: ICombinedMessage[];
  hasMore: boolean;
};

export const chatMachine = (
  room: IRoom,
  user: IUser,
  setAppState: (state: EAppStateType, reason: string) => void
) =>
  createMachine<IChatMachineBoxContext>(
    {
      id: "chatMachine",
      initial: "firstFetchingMessages",
      context: {
        firstItemIndex: 20000,
        messages: [] as ICombinedMessage[],
        hasMore: false,
      },
      invoke: [
        {
          id: "sendMessageService",
          src: "sendMessage",
        },
        {
          id: "uploadAttachmentService",
          src: "uploadFile",
        },
      ],
      states: {
        idle: {},
        firstFetchingMessages: {
          invoke: {
            id: "fetchMessages",
            src: "fetchMessages",
            onDone: {
              target: "firstFetchMessageSuccess",
              actions: ["updateHasMore", "updateMessages"],
            },
            onError: {
              target: "firstFetchMessageFailed",
            },
          },
        },
        firstFetchMessageSuccess: {},
        firstFetchMessageFailed: {},
        sendingMessage: {},
        uploadingMessage: {},
        sendMessageSuccess: {},
        sendMessageFail: {},
        fetchingMoreMessages: {
          invoke: {
            id: "fetchMoreMessages",
            src: "fetchMessages",
            onDone: {
              target: "idle",
              actions: ["updateHasMore", "updateMessages"],
            },
            onError: {
              target: "fetchMoreMessagesFailed",
            },
          },
        },
        fetchMoreMessagesFailed: {},
        newIncomingMessage: {},
      },
      on: {
        IDLE: {
          target: "idle",
        },
        SEND_MESSAGE: {
          target: "sendingMessage",
          actions: ["addMessage", "sendMessage"],
        },
        NEW_INCOMING_MESSAGE: {
          target: "newIncomingMessage",
          actions: ["appendIncomingMessage"],
        },
        SEND_MESSAGE_SUCCESS: {
          target: "idle",
          actions: "updateMessageStatus",
        },
        SEND_MESSAGE_FAILED: {
          target: "idle",
          actions: "updateMessageStatus",
        },

        UPLOAD_ATTACHMENT: {
          target: "uploadingMessage",
          actions: ["addMessage", "uploadFile"],
        },
        UPLOAD_ATTACHMENT_SUCCESS: {
          target: "idle",
          actions: "updateMessageStatus",
        },
        UPLOAD_ATTACHMENT_FAILED: {
          target: "idle",
          actions: "updateMessageStatus",
        },
        LOAD_MORE_MESSAGES: {
          target: "fetchingMoreMessages",
        },
      },
    },
    {
      actions: {
        addMessage: assign({
          messages: (context, event) => {
            const payload = event.payload;
            const msgType = event.msgType as EMessageType;
            const { messages } = context;
            // postback message
            if (msgType === EMessageType.POSTBACK) {
              const message = genInternalMessage(
                EMessageType.TEXT,
                EMessageStatus.SENDING,
                {
                  ...payload,
                  msg: payload.displayMsg,
                },
                dayjs().toISOString()
              );

              if (!message) return messages;
              messages.push(message);
              return messages;
            }
            if (msgType === EMessageType.LOCATION) {
              const message = genInternalMessage(
                EMessageType.LOCATION,
                EMessageStatus.SENDING,
                payload,
                dayjs().toISOString()
              );
              if (!message) return messages;
              messages.push(message);
              return messages;
            }
            // text message
            const message = genInternalMessage(
              msgType,
              EMessageStatus.SENDING,
              payload,
              dayjs().toISOString()
            );
            if (!message) return messages;
            messages.push(message);

            return messages;
          },
        }),
        appendIncomingMessage: assign({
          messages: (context, event) => {
            const { uid, newMessage } = event.payload as {
              uid: string;
              newMessage: IInCommingMessage;
            };
            // window active
            const { messages } = context;
            if (document.hasFocus()) {
              const sender =
                uid === newMessage.u._id ? ESender.USER : ESender.BOT;
              if (sender === ESender.BOT) {
                const message = transformMessage(
                  uid,
                  newMessage
                ) as ICombinedMessage;
                if (message) {
                  messages.push(message);
                }
                return messages;
              }
              return messages;
            } else {
              // find and update message
              const index = messages.findIndex((message) => {
                return message.id === newMessage._id;
              });

              if (index !== -1) {
                messages[index] = transformMessage(
                  uid,
                  newMessage
                ) as ICombinedMessage;
                return messages;
              }

              const message = transformMessage(
                uid,
                newMessage
              ) as ICombinedMessage;

              if (message) {
                messages.push(message);
              }
              return messages;
            }
          },
        }),
        updateMessages: assign({
          messages: (context, event) => {
            const payload = event.data as ICombinedMessage[];
            return payload;
          },
        }),
        updateHasMore: assign({
          hasMore: (context, event) => {
            const payload = event.data as ICombinedMessage[];

            return context.messages.length + MESSAGE_MAX_TAKE <= payload.length;
          },
        }),
        updateMessageStatus: assign({
          messages: (context, event) => {
            const payload = event.data as ICombinedMessage;
            const { messages } = context;
            const index = messages.findIndex(
              (message) => message.id === payload.id
            );
            if (index !== -1) {
              messages[index] = {
                ...payload,
              };
            }
            return messages;
          },
        }),
      },
      services: {
        fetchMessages: async (context, event) => {
          const msg = await Livechat.loadMessages(room._id, {
            token: user.token,
            limit: context.messages.length + MESSAGE_MAX_TAKE,
          });
          const transformMessage = messageTransformer(user._id, msg);
          return transformMessage.reverse();
        },
        uploadFile: (_, event) => async (cb) => {
          try {
            if (event.type === "UPLOAD_ATTACHMENT") {
              const file = event.file as File | null;
              if (file) {
                // Handle the selected files here
                const selectedFile = file;
                // upload file
                const result: IInCommingMessage = await Livechat.uploadFile({
                  rid: room._id,
                  file: selectedFile,
                });
                const message: ICombinedMessage | null = genInternalMessage(
                  event.msgType,
                  EMessageStatus.SENT,
                  event.payload,
                  result.ts
                );

                cb({
                  type: "UPLOAD_ATTACHMENT_SUCCESS",
                  data: message,
                });
              }
            }
          } catch (error) {
            console.error("error while upload file", error);

            const message: ICombinedMessage | null = genInternalMessage(
              event.msgType,
              EMessageStatus.ERROR,
              event.payload,
              Date.now().toString()
            );
            cb({
              type: "UPLOAD_ATTACHMENT_FAILED",
              data: message,
            });
          }
        },
        sendMessage: (_, event) => async (cb, onReceive) => {
          switch (event.type) {
            case "SEND_MESSAGE":
              const payload = event.payload;
              const msgType = event.msgType as EMessageType;

              try {
                // transform just key that required for send
                const outMessage: IOutGoingMessage = {
                  _id: payload._id,
                  msg: payload.msg,
                  token: payload.token,
                  rid: payload.rid,
                };
                const result: IOutGoingResponse = await Livechat.sendMessage(
                  outMessage
                );
                // location message
                if (msgType === EMessageType.LOCATION) {
                  const message: ICombinedMessage = {
                    id: result.message._id,
                    type: EMessageType.LOCATION,
                    sender: ESender.USER,
                    status: EMessageStatus.SENT,
                    createdAt: result.message.ts,
                    latitude: payload.lat,
                    longitude: payload.lng,
                    description: payload.displayMsg,
                  };
                  cb({
                    type: "SEND_MESSAGE_SUCCESS",
                    data: message,
                  });
                  return;
                }
                // other message
                const message: ICombinedMessage = {
                  id: result.message._id,
                  type: EMessageType.TEXT,
                  sender: ESender.USER,
                  text: replacePostback(result.message.msg),
                  status: EMessageStatus.SENT,
                  createdAt: result.message.ts,
                };
                cb({
                  type: "SEND_MESSAGE_SUCCESS",
                  data: message,
                });
              } catch (e) {
                console.error("error send message", e);
                const message: ICombinedMessage = {
                  id: payload._id,
                  type: EMessageType.TEXT,
                  sender: ESender.USER,
                  text: payload.msg,
                  status: EMessageStatus.ERROR,
                  createdAt: dayjs().toISOString(),
                };
                handleAppStateCatchError(e, setAppState);
                cb({
                  type: "SEND_MESSAGE_FAILED",
                  data: message,
                });
              }
          }
        },
      },
    }
  );
