import { ISdkIframePostMessage } from "sdk";
import { IFRAME_SENDER } from "@constants/iframe";
import { useEffect } from "react";
import { IAppSettings } from "@types";

type IUserIframeMessageParams = {
  listener: (data: ISdkIframePostMessage) => void;
  deps?: any[];
};

export const useIframeMessage = ({
  listener,
  deps,
}: IUserIframeMessageParams) => {
  useEffect(() => {
    window.addEventListener("message", onIframeMessage, false);

    return () => {
      window.removeEventListener("message", onIframeMessage, false);
    };
  }, deps);

  const onIframeMessage = (event: MessageEvent) => {
    const { data }: { data: ISdkIframePostMessage } = event;
    // get rid of the type that is not from ams_sdk
    if (!data.sender) return;
    if (data.sender !== IFRAME_SENDER) return;
    listener(data);
  };
};
