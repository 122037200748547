import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclipVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { TEXT_AREA_MAX_HEIGHT } from "@constants";
import { ISdkIframeSettings } from "sdk";

export type ITextComposerProps = {
  acceptFileTypes: string[];
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  message: string;
  placeholder?: string;
  isDisabled: boolean;
  attachment?: ISdkIframeSettings["attachment"];
  onType: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: (msg?: string | undefined) => Promise<void>;
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const TextComposer = ({
  acceptFileTypes = [],
  textAreaRef,
  fileInputRef,
  message,
  placeholder = "type something",
  isDisabled,
  attachment,
  onType,
  onSend,
  onInputFileChange,
}: React.PropsWithChildren<ITextComposerProps>) => {
  const _renderAttachment = useMemo(() => {
    // no attachment config enabled by default
    if (!attachment) {
      return (
        <AttachmentInput
          isDisabled={isDisabled}
          fileInputRef={fileInputRef}
          acceptFileTypes={acceptFileTypes}
          onInputFileChange={onInputFileChange}
        />
      );
    }
    // attachment is disabled
    if (attachment.disabled) {
      return null;
    }
    // attachment disabled is false, undefined, null or empty string (enabled)
    return (
      <AttachmentInput
        iconUrl={attachment.url}
        style={attachment.style}
        isDisabled={isDisabled}
        fileInputRef={fileInputRef}
        acceptFileTypes={acceptFileTypes}
        onInputFileChange={onInputFileChange}
      />
    );
  }, [attachment, isDisabled]);

  return (
    <>
      <textarea
        ref={textAreaRef}
        value={message}
        rows={1}
        disabled={isDisabled}
        onKeyDown={(e) => {
          const keyCode = e.which || e.keyCode;
          // enter but no shift
          if (keyCode === 13 && !e.shiftKey) {
            if (message.length > 0) {
              // if (isMobile) {
              //   return;
              // }
              onSend();
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "40px";
              }
            }
            e.preventDefault();
          }
        }}
        placeholder={placeholder}
        className={`max-h-[${TEXT_AREA_MAX_HEIGHT}px] 
        flex-1 resize-none overflow-y-hidden bg-[transparent]
        py-[8px] pr-[80px] text-[#222222] outline-none
        disabled:cursor-not-allowed disabled:opacity-50`}
        onChange={onType}
      />
      <div className="absolute right-[30px] flex gap-x-[8px]">
        {/* File input */}
        {_renderAttachment}
        {message ? (
          <button
            onClick={() => {
              onSend();
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "40px";
              }
            }}
            disabled={isDisabled}
            className="disabled:cursor-not-allowed disabled:opacity-50"
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              fontSize={20}
              color="rgba(80,35,20,var(--tw-text-opacity))"
              className="text-[20px]"
            />
          </button>
        ) : null}
      </div>
    </>
  );
};

type IAttachmentInputProps = {
  isDisabled: boolean;
  iconUrl?: string;
  fileInputRef: React.RefObject<HTMLInputElement>;
  acceptFileTypes: string[];
  style?: React.CSSProperties;
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

const AttachmentInput = ({
  iconUrl,
  isDisabled,
  acceptFileTypes,
  fileInputRef,
  style,
  onInputFileChange,
}: IAttachmentInputProps) => {
  return (
    <>
      <input
        type="file"
        disabled={isDisabled}
        accept={acceptFileTypes.join(",")}
        className="hidden"
        ref={fileInputRef}
        onChange={onInputFileChange}
      />
      <button
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        className="disabled:cursor-not-allowed disabled:opacity-50"
        disabled={isDisabled}
      >
        {iconUrl ? (
          <img src={iconUrl} style={style} />
        ) : (
          <FontAwesomeIcon
            icon={faPaperclipVertical}
            fontSize={20}
            color="rgba(80,35,20,var(--tw-text-opacity))"
            className="text-[20px]"
          />
        )}
      </button>
    </>
  );
};
